<template>
  <v-row align="center" justify="center" style="height: 100vh">
    <v-col cols="12" md="8" lg="4">
      <v-card>
        <v-card-title>
          <h3>Inicia sesión</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                prepend-inner-icon="mdi-email-outline"
                outlined
                label="Correo electrónico"
                hide-details
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-inner-icon="mdi-lastpass"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                outlined
                label="Contraseña"
                hide-details
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end py-6 pr-4">
          <v-btn color="primary" large @click="login">
            Iniciar sesión
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  mounted() {
    if (!!this.user.id) {
      this.$router.push("Home");
    }
  },
  computed: {
    ...mapState({
      server: (state) => state.cache.server,
      user: (state) => state.cache.user,
    }),
  },
  data: () => ({
    email: null,
    password: null,
    showPassword: true,
  }),
  methods: {
    login() {
      if (!!this.email && !!this.password) {
        let formData = new FormData();
        formData.append("email", this.email);
        formData.append("password", this.password);
        this.$store.dispatch("cache/login", formData);
      }
    },
  },
  watch: {
    user: function (val) {
      if (this.user.id !== null) {
        this.$router.push({
          name: "Reports",
        });
      }
    },
  },
};
</script>